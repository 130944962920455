module controllers {
    'use strict';
    export module reporting {
        interface IreportParameterScope extends ng.IScope { IsLoading: boolean; }

        interface IreportParameterParams extends ng.ui.IStateParamsService {
            reportId: number;
        }

        export class ReportParameterCtrl {
            static $inject = [
                "$scope",
                '$rootScope',
                'generalService',
                '$q',
                '$stateParams',
                "$anchorScroll",
                "$state",
                "bsLoadingOverlayService",
                "lookupService",
                "$timeout",
                '$transitions',
                'reportService'];

            reportId: number;
            searchAccordian: boolean = true;
            IsLoading: boolean = false;

            reportDetail: interfaces.reporting.IReport;
            reportParamaterList: Array<interfaces.reporting.IReportParameter>;

            breadCrumbDesc: string;

            constructor(
                private $scope: IreportParameterScope,
                private $rootScope: interfaces.applicationcore.IRootScope,
                public generalService: interfaces.applicationcore.IGeneralService,
                private $q: ng.IQService,
                private $stateParams: IreportParameterParams,
                private $anchorScroll: ng.IAnchorScrollService,
                private $state: ng.ui.IStateService,
                public bsLoadingOverlayService,
                private lookupService: interfaces.applicationcore.ILookupService,
                public $timeout: ng.ITimeoutService,
                public $transitions: ng.ui.core.ITransition,
                private reportService: interfaces.reporting.IReportService) {

                this.reportId = $stateParams.reportId;
                this.loadControls();

            }

            loadControls() {

                var loadPromises: ng.IPromise<any>[] = [];
                this.bsLoadingOverlayService.wrap({
                    referenceId: 'report.view'
                },
                    () => {
                        loadPromises.push(this.GetReportDetail());
                        loadPromises.push(this.GetReportParameters());
                        return this.$q.all(loadPromises);
                    });
            }

            GetReportDetail() {
                this.IsLoading = true;
                return this.reportService.GetReportDetail(this.reportId).get((data) => {

                    this.reportDetail = data;

                    this.breadCrumbDesc = 'Report Parameters: ' +  this.reportDetail.Code + ' - ' + this.reportDetail.LocaleDescription;

                }, (data) => {
                    this.IsLoading = false;
                }, (errorResponse) => {
                    this.generalService.displayMessageHandler(<interfaces.applicationcore.IMessageHandler>errorResponse.data);
                }).$promise;
            }

            GetReportParameters() {
                this.IsLoading = true;
                return this.reportService.GetReportParameters(this.reportId).query((data) => {

                    this.reportParamaterList = data;    
                    

                }, (data) => {
                    this.IsLoading = false;
                }, (errorResponse) => {
                    this.generalService.displayMessageHandler(<interfaces.applicationcore.IMessageHandler>errorResponse.data);
                }).$promise;
            }

            SaveReport_Click() {
                this.$scope.IsLoading = true;

                var searchObject: interfaces.applicationcore.ISearchObject = {
                    filters: [],
                    sorts: []
                }              

                var HasErrors = false;

                angular.forEach(this.reportParamaterList, (Value,Id) => {

                    searchObject.filters.push({ Name: Value.ParameterName, Value: Value.Value });

                    if (Value.IsRequired && !Value.Value) {
                        HasErrors = true;
                        //this.generalService.displayMessage("Mandatory Parameter " + Value.LabelCaption + " is required.", Enum.EnumMessageType.Error);
                        this.$rootScope.Messages.push({
                            LocaleDescription: "Mandatory Parameter " + Value.LabelCaption + " is required.",
                            TypeEnum: Enum.EnumMessageType.Warning
                        })
                    }

                });

                var params = {
                    connectSearch: searchObject,
                    reportId: this.reportId
                }

                if (!HasErrors) {

                    this.bsLoadingOverlayService.wrap({
                        referenceId: 'report.view'
                    },
                        () => {
                            this.IsLoading = true;
                            return this.reportService.getReportExcel(params).then(() => {
                                this.$scope.IsLoading = false;
                            }, (data) => {
                                this.$scope.IsLoading = false;
                                this.IsLoading = false;
                                this.generalService.displayMessageHandler(<interfaces.applicationcore.IMessageHandler>data.data);
                            });
                        });

                 
                }
            }
        }

        angular.module("app").controller("reportParameterCtrl", controllers.reporting.ReportParameterCtrl);
    }
}